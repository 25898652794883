<template>
    <div class="flex w-full flex-column justify-center items-center container">
        <img src="../assets/checkedv2.png" width="120" />
        <div class="mt-6">
            <h2 class="text-xl">Submitted successfully</h2>
        </div>
        <div class="mt-6">
            <h2
                class="text-sm"
                style="cursor: pointer; text-decoration: underline;"
                @click="goBack"
            >Go back</h2>
        </div>
    </div>
</template>

<script setup>
import { useRouter } from 'vue-router';

const router = useRouter();
const goBack = () => {
    router.push({ name: "HomePage" })
}
</script>

<style>
.container {
    height: calc(100vh - 80px);
    flex-direction: column;
}
</style>